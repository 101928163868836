<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>Stock Management</v-tab>
      <v-tab>Cart</v-tab>
      <v-tab>Transactions</v-tab>
      <v-tab>Stock Chart</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Stock Management Tab -->
      <v-tab-item>
        <v-card class="pa-4">
          <v-btn color="success" @click="openEditDialog">
            <v-icon left>mdi-plus</v-icon>
            Add New Item
          </v-btn>

          <v-data-table
            :headers="stockHeaders"
            :items="items"
            class="elevation-1 mt-5"
            :items-per-page="10"
          >
            <template v-slot:item.image="{ item }">
              <v-img class="my-1"
                :src="item.image"
                max-width="50"
                max-height="50"
                contain
              ></v-img>
            </template>

            <template v-slot:item.stock="{ item }">
              <v-chip
                :color="item.stock > 0 ? 'success' : 'error'"
                label
              >
                {{ item.stock == 0 ? 'OUT OF STOCK' : item.stock }}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="openEditDialog(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="mr-2" @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
              <v-icon small @click="addToCart(item)">
                mdi-cart-plus
              </v-icon>
            </template>
          </v-data-table>

          <!-- Edit/Create Dialog -->
          <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ dialogTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="editForm" v-model="editValid">
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[v => !!v || 'Name is required']"
                    label="Item Name"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.price"
                    :rules="[v => !!v || 'Price is required', v => v > 0 || 'Price must be positive']"
                    label="Price"
                    type="number"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.costPrice"
                    :rules="[v => !!v || 'Cost Price is required', v => v >= 0 || 'Cost cant be negative']"
                    label="Cost Price"
                    type="number"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="editedItem.stock"
                    :rules="[v => !!v || 'Stock is required', v => Number.isInteger(+v) || 'Stock must be an integer']"
                    label="Stock Quantity"
                    type="number"
                    required
                  ></v-text-field>

                  <v-file-input
                    v-model="editedItem.image"
                    label="Item Image"
                    accept="image/*"
                    :placeholder="editedItem.image ? 'Change image' : 'Select image'"
                  ></v-file-input>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeEditDialog">Cancel</v-btn>
                <v-btn :disabled="!editValid" color="primary" @click="saveItem">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-tab-item>

      <!-- Cart Tab -->
      <v-tab-item>
        <v-card class="pa-4">
          <v-data-table
            :headers="cartHeaders"
            :items="cart"
            class="elevation-1"
            :items-per-page="5"
          >
            <template v-slot:item.image="{ item }">
              <v-img class="my-1"
                :src="item.image"
                max-width="50"
                max-height="50"
                contain
              ></v-img>
            </template>

            <template v-slot:item.total="{ item }">
              {{ (item.price * item.quantity).toFixed(2) }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small @click="removeFromCart(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>

          <v-divider class="my-4"></v-divider>

          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-card>
                <v-card-text>
                  <div class="d-flex justify-space-between">
                    <span>Total:</span>
                    <strong>${{ total }}</strong>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="checkout" :disabled="cart.length === 0">
                    Checkout
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <!-- Transactions Tab -->
      <v-tab-item>
        <v-card class="pa-4">
          <v-data-table
            :headers="transactionHeaders"
            :items="transactions"
            class="elevation-1"
            :items-per-page="10"
            :loading="loadingTransactions"
          >
            <template v-slot:item.date="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:item.items="{ item }">
              <ul>
                <li v-for="(cartItem, index) in item.items" :key="index">
                  {{ cartItem.name }} x {{ cartItem.quantity }} x ${{ cartItem.price }}
                </li>
              </ul>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="pa-4">
          <v-select
            v-model="selectedItem"
            :items="items"
            item-text="name"
            label="Select Item"
            return-object
          ></v-select>

          <v-divider class="my-4"></v-divider>

          <div v-if="stockChartData && earningsChartData">
            <apexchart
              type="line"
              :options="stockChartOptions"
              :series="stockChartData"
            ></apexchart>

            <v-divider class="my-4"></v-divider>

            <apexchart
              type="line"
              :options="earningsChartOptions"
              :series="earningsChartData"
            ></apexchart>
          </div>
        </v-card>
      </v-tab-item>
    
    </v-tabs-items>
  </v-container>
</template>

<script>
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,

  doc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: ApexCharts,
  },
  name: "StoreManager",
  data() {
    return {
      tab: null,
      db: getFirestore(),
      storage: getStorage(),
      items: [],
      stockHeaders: [
        { text: "Image", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Price ($)", value: "price" },
        { text: "Stock", value: "stock" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editDialog: false,
      editValid: false,
      editedItem: {
        id: "",
        name: "",
        price: null,
        costPrice: null,
        stock: null,
        image: null,
      },
      dialogTitle: "Add New Item",
      cart: [],
      cartHeaders: [
        { text: "Image", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Price ($)", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "Total ($)", value: "total", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      transactions: [],
      transactionHeaders: [
        { text: "Date", value: "date" },
        { text: "Total ($)", value: "total" },
        { text: "Items", value: "items" },
      ],
      loadingTransactions: true,
      selectedItem: null,
      stockChartData: null,
      earningsChartData: null,
      stockChartOptions: {
        chart: {
          id: 'stock-chart',
          type: 'line',
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Date',
          },
        },
        yaxis: {
          title: {
            text: 'Stock Level',
          },
        },
        title: {
          text: 'Stock Changes Over Time',
        },
      },
      earningsChartOptions: {
        chart: {
          id: 'earnings-chart',
          type: 'line',
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Date',
          },
        },
        yaxis: {
          title: {
            text: 'Cumulative Earnings ($)',
          },
        },
        title: {
          text: 'Earnings Over Time',
        },
      }
    };
  },
  computed: {
    total() {
      return this.cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
    },
  },
  created() {
    this.fetchItems();
    this.listenToTransactions();
  },
  watch: {
    selectedItem(newItem) {
      if (newItem) {
        this.fetchStockChangeData(newItem.id);
      }
    },
  },
  methods: {
    async fetchStockChangeData(itemId) {
      try {
        const stockChangesRef = collection(this.db, `items/${itemId}/stockChanges`);
        const q = query(stockChangesRef, orderBy('date', 'asc'));
        const querySnapshot = await getDocs(q);

        const stockData = [];
        const earningsData = [];
        let cumulativeEarnings = 0;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const date = data.date.toDate();

          stockData.push({ x: date, y: data.newStock });

          if (data.reason === 'sale') {
            const earnings = data.change * -1 * this.selectedItem.price;
            cumulativeEarnings += earnings;
            earningsData.push({ x: date, y: cumulativeEarnings });
          }
        });

        this.stockChartData = [{ name: 'Stock Level', data: stockData }];
        this.earningsChartData = [{ name: 'Cumulative Earnings', data: earningsData }];
      } catch (error) {
        console.error('Error fetching stock change data:', error);
      }
    },
    
    openEditDialog(item = null) {
      if (item) {
        this.dialogTitle = "Edit Item";
        this.editedItem = { ...item, image: null };
        this.editedItem.existingImage = item.image;
      } else {
        this.dialogTitle = "Add New Item";
        this.editedItem = { id: "", name: "", price: null, stock: null, image: null, existingImage: "" };
      }
      this.editDialog = true;
    },
    
    closeEditDialog() {
      this.editDialog = false;
      if (this.$refs.editForm) {
        this.$refs.editForm.reset();
      }
      this.editedItem = {
        id: "",
        name: "",
        price: null,
        stock: null,
        image: null,
        existingImage: ""
      };
      this.dialogTitle = "Add New Item";
    },
    
    async saveItem() {
  try {
    let imageURL = this.editedItem.existingImage || "";
    if (this.editedItem.image && this.editedItem.image instanceof File) {
      const imagePath = `items/${Date.now()}_${this.editedItem.image.name}`;
      const imgRef = storageRef(this.storage, imagePath);
      const snapshot = await uploadBytes(imgRef, this.editedItem.image);
      imageURL = await getDownloadURL(snapshot.ref);
    }

    let itemRef;
    let previousStock = 0;

    if (this.editedItem.id) {
      itemRef = doc(this.db, "items", this.editedItem.id);
      const itemSnapshot = await getDoc(itemRef); // Use getDoc() instead of getDocs()
      if (itemSnapshot.exists()) {
        previousStock = itemSnapshot.data().stock;
      }

      await updateDoc(itemRef, {
        name: this.editedItem.name,
        price: parseFloat(this.editedItem.price),
        costPrice: parseFloat(this.editedItem.costPrice),
        stock: parseInt(this.editedItem.stock),
        image: imageURL,
        updatedAt: serverTimestamp(),
      });
    } else {
      itemRef = await addDoc(collection(this.db, "items"), {
        name: this.editedItem.name,
        price: parseFloat(this.editedItem.price),
        costPrice: parseFloat(this.editedItem.costPrice),
        stock: parseInt(this.editedItem.stock),
        image: imageURL,
        createdAt: serverTimestamp(),
        deleted: false,
      });
    }

    const newStock = parseInt(this.editedItem.stock);
    const stockDifference = newStock - previousStock;

    if (stockDifference > 0) {
      const totalSpent = stockDifference * parseFloat(this.editedItem.costPrice);

      await addDoc(collection(itemRef, "stockChanges"), {
        date: serverTimestamp(),
        change: stockDifference,
        reason: "stock addition",
        newStock: newStock,
        costPrice: parseFloat(this.editedItem.costPrice),
        totalSpent: totalSpent,
      });
    } else {
      await addDoc(collection(itemRef, "stockChanges"), {
        date: serverTimestamp(),
        change: stockDifference,
        reason: "manual update",
        newStock: newStock,
      });
    }

    this.closeEditDialog();
  } catch (error) {
    console.error("Error saving item:", error);
  }
}
,
    
    async fetchItems() {
      try {
        const itemsCollection = collection(this.db, "items");
        const q = query(itemsCollection, where("deleted", "==", false));
        const querySnapshot = await getDocs(q);
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({ id: doc.id, ...doc.data() });
        });

        onSnapshot(q, (snapshot) => {
          this.items = [];
          snapshot.forEach((doc) => {
            this.items.push({ id: doc.id, ...doc.data() });
          });
        });
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    },

    async deleteItem(id) {
      try {
        const itemRef = doc(this.db, "items", id);
        await updateDoc(itemRef, { deleted: true });
        console.log("Item logically deleted.");
      } catch (error) {
        console.error("Error logically deleting item:", error);
      }
    },

    addToCart(item) {
      const existing = this.cart.find((cartItem) => cartItem.id === item.id);
      if (existing) {
        if (existing.quantity < item.stock) {
          existing.quantity += 1;
        } else {
          alert("Not enough stock available.");
        }
      } else {
        this.cart.push({ ...item, quantity: 1 });
      }
    },

    removeFromCart(item) {
      const index = this.cart.findIndex((cartItem) => cartItem.id === item.id);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
    },

    async checkout() {
      if (this.cart.length === 0) {
        alert("Your cart is empty.");
        return;
      }

      try {
        for (let cartItem of this.cart) {
          const itemRef = doc(this.db, "items", cartItem.id);
          const newStock = cartItem.stock - cartItem.quantity;

          await updateDoc(itemRef, {
            stock: newStock,
            updatedAt: serverTimestamp(),
          });

          await addDoc(collection(itemRef, "stockChanges"), {
            date: serverTimestamp(),
            change: -cartItem.quantity,
            reason: "sale",
            newStock: newStock,
          });
        }

        const transactionRef = collection(this.db, "transactions");
        const transactionData = {
          items: this.cart.map((item) => ({
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
          })),
          total: parseFloat(this.total),
          date: serverTimestamp(),
        };
        await addDoc(transactionRef, transactionData);

        this.cart = [];
        alert("Checkout successful!");
      } catch (error) {
        console.error("Error during checkout:", error);
        alert("Checkout failed.");
      }
    },

    listenToTransactions() {
      const transactionsQuery = query(
        collection(this.db, "transactions"),
        orderBy("date", "desc")
      );
      onSnapshot(transactionsQuery, (snapshot) => {
        this.transactions = [];
        snapshot.forEach((doc) => {
          this.transactions.push({ id: doc.id, ...doc.data() });
        });
        this.loadingTransactions = false;
      });
    },

    formatDate(timestamp) {
      if (!timestamp) return "";
      const date = timestamp.toDate();
      return date.toLocaleString();
    },
  },
};
</script>

<style scoped>
.v-data-table img {
  max-width: 100px;
}
</style>
